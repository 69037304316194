<template>
  <div ref="container" class="relative min-h-[10rem] w-full">
    <Bar v-if="props.type === 'bar'" ref="chart" :data="chartData" :options="props.options || options"
      class="h-full w-full" />
    <Line v-else ref="chart" :data="chartData" :options="props.options || options" class="h-full w-full" />
  </div>
</template>

<script setup>
import { Chart as ChartJS, CategoryScale, LinearScale, TimeScale, Filler, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Line, Bar } from 'vue-chartjs'
import 'chartjs-adapter-moment'

ChartJS.register(CategoryScale, LinearScale, TimeScale, Filler, PointElement, LineElement, BarElement, Title, Tooltip, Legend)

const props = defineProps({
  type: {
    type: String,
    default: 'line',
  },
  datas: {
    type: Array,
    default: [],
  },
  labels: {
    type: Array,
    default: [],
  },
  options: Object
})

const container = ref(null)
const chart = ref(null)

onMounted(() => {
  setTimeout(() => {
    if (chart.value)
      chart.value.chart.resize()
  }, 500)
})

const options = ref({
  responsive: true,
  locale: 'zh-TW',
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        displayFormats: {
          day: 'MM/DD',
        },
        tooltipFormat: 'YYYY-MM-DD',
        unit: 'day',
      },
    },
  },
})

const chartData = computed(() => ({
  labels: props.labels,
  datasets: props.datas.map((e, i) => ({
    label: e.label || `dataset-${i}`,
    data: e.data,
    cubicInterpolationMode: 'monotone',
    fill: 'start',
    hoverBorderColor: e.hoverBorderColor || '#3e95cd',
    borderColor: e.borderColor || '#3e95cd99',
    backgroundColor: e.backgroundColor || '#3e95cd50',
    tension: 0.1,
  })),
}))
</script>